import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Image from "../../../components/image"
import ImageFrame from "../../../components/image-frame"
import Inline from "../../../components/inline"
import Icon from "../../../components/icon"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"
import WordFlip from "../../../components/word-flip"
import PaperStack from "../../../components/paper-stack"
import Constrain from "../../../components/constrain"

const HoelderlinLiebtFreiheit = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        titelbild: file(
          relativePath: { eq: "hoelderlin-liebt/einleitung-freiheit.jpg" }
        ) {
          ...mediumImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt die Freiheit"
        description="Die letzten 36 Jahre seines Lebens verbringt Hölderlin bei der Familie Zimmer in Tübingen."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "… die Freiheit",
                link: "/sonderausstellungen/hoelderlin-liebt/freiheit",
              },
            ]}
          />
          <PageTitle>Hölderlin liebt die Freiheit</PageTitle>
          <Grid columns={[1, 2]} alignY="center">
            <Stack>
              <Paragraph dropcap={true}>
                Hölderlins Mutter möchte, dass ihr Sohn Pfarrer wird. <br />
                Dafür muss man studieren. <br />
                So kommt Hölderlin mit 18 Jahren zum ersten Mal nach Tübingen.{" "}
                <br />
                Das Studium gefällt ihm aber gar nicht. <br />
                Jeden Tag muss er tun, was die Lehrer sagen und
                <br />
                jeden Tag um sechs Uhr aufstehen, <br />
                jeden Tag im Zimmer sitzen, wenn draußen die Sonne scheint,{" "}
                <br />
                jeden Tag Latein sprechen, <br />
                jeden Tag alles auswendig lernen, <br />
                jeden Tag gehorsam sein.
              </Paragraph>
            </Stack>
            <ImageFrame>
              <Image
                image={data.titelbild.childImageSharp.gatsbyImageData}
                alt="Eine Illustration zeigt Hölderlin. Er marschiert mit großen Schritten."
              />
            </ImageFrame>
          </Grid>
        </Stack>

        <Paragraph>
          Hölderlin denkt: Gott ist bestimmt nicht in der Kirche. <br />
          Er ist draußen, in jedem Grashalm, in jedem Käfer, <br />
          in Allem, was es gibt. <br />
          Er möchte als Dichter von der Schönheit der Natur <br />
          und von der Freiheit erzählen. <br />
          Ein Dichter ist doch auch ein Botschafter Gottes.
          <br />
          Aber seine Mutter kann das nicht verstehen.
          <br />
          Pfarrer oder Dichter? Sie und Hölderlin streiten ein Leben lang
          darüber.
        </Paragraph>

        <Stack>
          <Stack space={6}>
            <Heading as="h2" level={3}>
              Hölderlins Gedicht ›Zornige Sehnsucht‹ in Einfacher Sprache
            </Heading>
            <Paragraph>
              Dieses Gedicht hat Hölderlin als Student in Tübingen geschrieben.{" "}
              <br />
              Eigentlich hat es keinen Titel. <br />
              Aber weil darin so viel Zorn und so viel Sehnsucht ist, <br />
              wird es heute ›Zornige Sehnsucht‹ genannt.
            </Paragraph>
          </Stack>

          <Constrain align="right">
            <Stack>
              <Box bg="muted" p={6}>
                <Paragraph size={[3, 3, 4]}>
                  Hier können Sie die ersten Zeilen des Gedichts lesen. <br />
                  Wenn Sie auf das{" "}
                  <Box
                    as="span"
                    display="inline-flex"
                    ml={1}
                    bg="primary"
                    color="background"
                    borderRadius="full"
                  >
                    <Icon size={4} icon="plus" />
                  </Box>
                  -Zeichen klicken, <br />
                  erhalten Sie eine Übersetzung in Einfache Sprache.
                </Paragraph>
              </Box>
              <PaperStack>
                <Stack space={6}>
                  <Inline>
                    <WordFlip flipped="Ich ertrag">Ich duld‘</WordFlip>
                    <WordFlip flipped="es nicht mehr!">es nimmer!</WordFlip>
                    <WordFlip flipped="Jeden Tag">ewig und ewig so</WordFlip>
                  </Inline>
                  <Inline>
                    <WordFlip flipped="Die kleinen Schritte,">
                      Die Knabenschritte,
                    </WordFlip>
                    <WordFlip flipped="wie ein Gefangener">
                      wie ein Gekerkerter
                    </WordFlip>
                  </Inline>
                  <Inline>
                    <WordFlip flipped="Die kurzen, vorgeschriebenen Schritte">
                      Die kurzen vorgemeßnen Schritte
                    </WordFlip>
                  </Inline>
                  <Inline>
                    <WordFlip flipped="Jeden Tag zu gehen.">
                      Täglich zu wandeln,
                    </WordFlip>
                    <WordFlip flipped="Ich ertrag">ich duld‘</WordFlip>
                    <WordFlip flipped="es nicht mehr!">es nimmer!</WordFlip>
                  </Inline>
                </Stack>
              </PaperStack>
            </Stack>
          </Constrain>
        </Stack>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtFreiheit
